/* BattleITOut Start */
.BattleItOutContainer {
  margin-top: 240px;
}

.BattleItOutContainerCentered {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 50px;
  grid-row-gap: 100px;
  width: 65%;
  margin: auto;
  padding: 70px 0px;
}

.BattleItOutContainer h5 {
  color: #FFF;
  text-align: center;
  font-family: var(--font-poppins);
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 1px;
}

.BattleItOutContainer h2 {
  color: #FFF;
  text-align: center;
  font-family: var(--font-pink-and-crimson);
  font-size: 70px;
  font-weight: 400;
}

.BattleItOutBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.BattleItOutBox .landlarge-img {
  width: 100%;
  transition: all 0.2s ease;
}

.BattleItOutBox .landlarge-img:hover {
  transform: scale(1.05);
}

.BattleItOutBox .landsmall-img {
  margin-top: 30px;
}

.BattleItOutBox h5 {
  color: #FFF;
  text-align: center;
  font-family: var(--font-pink-and-crimson);
  font-size: 18px;
  font-weight: 100;
  margin-top: 25px;
  letter-spacing: 1px;
}

/* BattleITOut End */


/* Responsiveness */
@media only screen and (max-width: 1050px) {
  .BattleItOutContainerCentered {
    width: 80%;
  }

  .BattleItOutContainer {
    margin-top: 300px;
  }
}

@media only screen and (max-width: 750px) {
  .BattleItOutContainerCentered {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 700px) {
  .BattleItOutContainer {
    margin-top: 150px;
  }
}

@media only screen and (max-width: 450px) {
  .BattleItOutContainerCentered {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 50px;

  }

  .BattleItOutContainer h5 {
    padding: 0px 10px;
    font-size: 17px;
  }

  .BattleItOutContainer h2 {
    padding: 0px 10px;
    font-size: 40px;
  }
}